import React from "react";
import logo from "../logo/logo_full.png";

const Navbar = function () {
  return (
    <div className="navbar-wrapper">
      <img src={logo} alt="AngleScript Logo" />
    </div>
  );
};

export default Navbar;
